import React, { useMemo, useEffect, useState } from 'react';
import { ShellProps } from '../src/types/shell';
import { Root as RootShellNav } from '../src';
import projectNames from '../src/configs/projectNames';
import { ToastProvider, DirectionProvider } from '@veneer/core';
import RootProvider from '../src/contexts/Root/RootProvider';
import { ThemeProvider } from '@veneer/theme';

/**
 *
 * @param props
 */
export default function Root(props) {
  const { v1 } = window.Shell as ShellProps;
  const { theme } = v1 || {};

  const themeProviderProps = useMemo(
    () => theme?.getThemeProviderProperties(),
    [theme]
  );

  const directionValue =
    v1?.localization?.useReactGetLanguageDirection?.(React);

  const { tenantHandlerInterface } = v1;

  const [sectionKeyId, setSectionKeyId] = useState(0);

  useEffect(() => {
    const removeListener = tenantHandlerInterface?.onTenantChange?.(
      ({ preventReload }) => {
        if (!preventReload) setSectionKeyId((prev) => prev + 1);
      }
    );
    return () => removeListener();
  }, [tenantHandlerInterface]);

  return (
    <section
      key={sectionKeyId}
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <DirectionProvider direction={directionValue || 'auto'}>
        <ThemeProvider {...themeProviderProps}>
          <RootProvider
            id={projectNames.packageJsonName}
            {...{ ...props, ...v1 }}
          >
            <ToastProvider>
              <RootShellNav {...{ ...props, ...v1 }} />
            </ToastProvider>
          </RootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  );
}
