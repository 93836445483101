import React from 'react';
import * as S from './styles';
import { useRootContext } from '../../contexts/Root';
import createId from '../../utils/createId';
import { DynamicIcon } from '../RecursiveSideMenuItem/DynamicIcon';

const MfeNotFound = (props) => {
  const { localization } = useRootContext();
  const { t } = localization.useReactTranslatorHook();

  return (
    <S.Container
      data-testid={createId('mfe_not_found')}
      {...props}
    >
      <DynamicIcon iconRef="IconBatteryCharging" />
      {t('nav.not-found.content', 'This content is under development...')}
    </S.Container>
  );
};

export default MfeNotFound;
