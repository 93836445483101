import styled from 'styled-components';
import { ProgressIndicator } from '@veneer/core';

export type ShellProgressIndicatorPropsType = {
  width?: number;
  height?: number;
};

const ShellProgressIndicator = styled(ProgressIndicator)`
  & > [role='progressbar'] {
    width: ${(props: ShellProgressIndicatorPropsType) =>
      props?.width || '24'}px;
    height: ${(props: ShellProgressIndicatorPropsType) =>
      props?.width || '24'}px;
  }
`;

export default ShellProgressIndicator;
