import React, { useEffect, useState } from 'react';
import logger from '../../interfaces/logger';
import { IconPropsType } from '../SelectableButton/types';

type DynamicIconProps = IconPropsType & {
  iconRef: string;
};

export const DynamicIcon = ({
  iconRef,
  filled = false,
  ...props
}: DynamicIconProps) => {
  const [Icon, setIcon] = useState(null);

  useEffect(() => {
    const filename = iconRef
      ?.replace(/([A-Z])([A-Z][a-z])|([a-z0-9])([A-Z])/g, '$1$3_$2$4')
      .toLowerCase();

    if (filename) {
      import(
        /*webpackMode: "lazy-once", webpackChunkName: "icons-bundle"*/ `@veneer/core/dist/esm/scripts/icons/${iconRef}.js`
      )
        .then((icon) => {
          setIcon(icon?.default);
        })
        .catch((error) => {
          logger?.error?.('Error on fetching icon:', error);
        });
    }
  }, [iconRef]);

  if (Icon) {
    return (
      <Icon
        filled={filled}
        {...props}
      />
    );
  }
  return null;
};
